<script setup lang="ts">
import { defineAsyncComponent } from 'vue';
import { computed } from 'vue';

const defaultLayout = defineAsyncComponent(() => import('@/layout/DefaultLayout.vue'))

const authedLayout = '';

const layout = computed(() => {
  const AuthenticationOk = false;
  if (AuthenticationOk) {
    return authedLayout;
  }

  return defaultLayout;
})
</script>

<template>
  <component :is="layout" />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
