<script setup lang="ts">
</script>

<template>
  <div class="prologue">
    <v-row>
      <v-img width="90%" :src="require('@/assets/background-010.jpg')">
        <div class="overlay-image-2nd-lang">
          <h3 style="color:#cdb22e">JavaScript</h3>
          <h3 style="color:#0076c6">TypeScript</h3>
          <h3 style="color:aqua">Go</h3>
          <!-- <v-img width="10%" class="overlay-image" :src="require('@/assets/lang-javascript.png')" />
          <v-img width="10%" class="overlay-image" :src="require('@/assets/lang-typescript.png')" />
          <v-img width="10%" class="overlay-image" :src="require('@/assets/lang-go.png')" /> -->
        </div>
        <div class="overlay-image-1st-lang">
          <h4 style="color:#2e2e2e">C</h4>
          <h4 style="color:#da4838">Java</h4>
          <h4 style="color:#3c4548">Bash</h4>
          <!-- <v-img width="10%" class="overlay-image" :src="require('@/assets/lang-c.png')" />
          <v-img width="100%" class="overlay-image" :src="require('@/assets/lang-java.png')" /> -->
        </div>
        <div class="overlay-image-db">
          <h3 style="color:#31648c">PostgreSql</h3>
          <h3 style="color:#005e86">MySql</h3>
          <h4 style="color:#c91fee">Oracle</h4>
        </div>
      </v-img>
    </v-row>
  </div>

</template>

<style>
.overlay-image-2nd-lang {
  position: absolute;
  bottom: 128px;
  right: 128px;
}
.overlay-image-1st-lang {
  position: absolute;
  bottom: 32px;
  right: 64px;
}
.overlay-image-cloud {
  position: absolute;
  bottom: 32px;
  right: 64px;
}
.overlay-image-db {
  position: absolute;
  bottom: 256px;
  right: 64px;
}
</style>