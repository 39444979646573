import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {aliases, mdi }from 'vuetify/iconsets/mdi'

export const vuetify = createVuetify({
  ssr: true,
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
  },
  theme: {
    defaultTheme: 'myTheme',
    themes: {
      light: {
        colors: {
          'light-blue': '#80d8ff',
        },
      },
      myTheme: {
        colors: {
          primary: '#1976d2',
          bgColor: '#f0f',
          tnBackg: '#cbf0ec',
          tnHeadBackg: '#72c1c4',
          tnButton: '#72cdce',
          tnBoxLight: '#fef4ea',
          tnBoxNormal: '#9de4e0',
          tnBoxGrey: '#ffa88f',
          tnBoxHot: '#f6decb',
          tnBoxNormalDark: '#62b1b2'
        },
      }
    }
  },
})